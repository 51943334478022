<template>
    <div class="about-main">
        <top-nav></top-nav>
        <header-guide></header-guide>
        <bread-crumb></bread-crumb>
        <div
            class="sp-banner"
            :style="{backgroundImage:'url('+$img.protocol_banner+')'}"
        >
            <div class="container">
                <div class="sp-banner-txt">协议规则</div>
            </div>
        </div>
        <div class="main">
            <div class="container">
                <aside class="aside-panel">
                    <ul class="col-items">
                        <li
                            v-for="item in cateList"
                            :key="item.id"
                            :class="{'active':currentIndex==item.id}"
                            @click="toggle(item.id)"
                        >
                            <a href="javascript:void(0)">{{item.title}}</a>
                        </li>
                    </ul>
                </aside>
                <div class="contarea-panel">
                    <div class="contarea">
                        <div class="about-txt-cont">
                            <h4
                                class="title"
                                v-text="article.title"
                            ></h4>

                            <p
                                class="date"
                                v-text="article.create_time"
                            ></p>

                            <div
                                class="desc"
                                v-html="article.content"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-panel">
                    <sidebar-panel></sidebar-panel>
                </div>
            </div>
        </div>

        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import SidebarPanel from "@/components/common/SidebarPanel.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "protocol",
    metaInfo() {
        return {
            title: this.article_title + " - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: this.article_content
                }
            ]
        }
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        SidebarPanel, //右边公共模块
        GlobalFooter //公共底部
    },
    created() {
        this.getProNav();
        this.getDetail();
    },
    data() {
        return {
            currentIndex: 0,
            cateList: [],
            article: [],
            id: 0,
            article_title: '',
            article_content: ''
        };
    },
    methods: {
        toggle(index) {
            this.currentIndex = index;
            this.id = index;
            this.getDetail();
        },
        //获取文章详情
        getDetail() {
            let _this = this;
            if (_this.id == 0) {
                this.id = this.$route.params.id;
            }
            _this.currentIndex = _this.id;
            //加载内容
            this.post(
                "/article/index/detail",
                {
                    id: _this.id //查询的id
                },
                function (res) {
                    if (res.code == 200) {
                        //seo
                        _this.article_title = res.data.title;
                        _this.article_content = res.data.content.replace(/<[^>].*?>/g, "").replace(/&nbsp;/g, "").substr(0, 300);//截取文章内容，去除html标签

                        _this.article = res.data;
                    }
                }
            );
        },
        //获取文章列表
        getProNav() {
            let _this = this;
            this.post(
                "/article/index/get_list_cate",
                {
                    cate: 3,
                    limit: 50
                },
                function (res) {
                    if (res.code == 200) {
                        _this.cateList = res.data[3].list;
                    }
                }
            );
        }
    },
    watch: {
        $route() {
            this.id = this.$route.params.id;
            this.getDetail();
        }
    }
};
</script>

<style lang="less" scoped>
.col-items a {
    padding: 0 20px;
    text-align: left;
}
.about-txt-cont {
    .title {
        text-align: center;
    }
    .date {
        text-align: center;
        margin-bottom: 30px;
        font-size: 12px;
        color: #999;
    }
}
.aside-panel {
    padding: 0px;
    li a {
        height: 40px;
        overflow: hidden;
        display: block;
        line-height: 40px;
        font-size: 14px;
        color: #666;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            color: #587ddc;
        }
        &.active {
            color: #587ddc;
        }
    }
    li.active a {
        background: #587ddc;
        color: #fff;
    }
}
</style>




